import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../styles/FormPopup.css";

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_SWAGGER_ENDPOINT,
  REACT_APP_AUTH_USERNAME,
  REACT_APP_AUTH_PASSWORD,
} = process.env;

const UserRegistrationForm = ({ step, setStep }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [brand, setBrand] = useState("");
  const [frequency, setFrequency] = useState("");
  const [concerns, setConcerns] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    getBrandsName();
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const allFieldsFilled = () => {
    return validateEmail(email) && firstName !== "" && lastName !== "";
  };

  const questionFieldsFilled = () => {
    return brand !== "" && frequency !== "" && concerns !== "";
  };

  const getBrandsName = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/getSupportedBrands`,
        {
          auth: {
            username: REACT_APP_AUTH_USERNAME,
            password: REACT_APP_AUTH_PASSWORD,
          },
        }
      );
      setBrands(response.data);
    } catch (error) {
      console.error("Failed to get brands:", error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (submitted || loading) return;

    setLoading(true);

    try {
      await Promise.all([
        axios.post(`${REACT_APP_API_ENDPOINT}/form`, {
          email,
          firstName,
          lastName,
          brand,
          frequency,
          concerns,
        }),
        axios.post(`${REACT_APP_API_ENDPOINT}/email`, {
          email,
          firstName,
        }),
      ]);

      setLoading(false);
      setSubmitted(true);
      setStep(3);
    } catch (error) {
      setLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <div className="Form">
      {!submitted && (
        <>
          {step === 1 && (
            <div>
              <h1>Get early access!</h1>
              <p>
                Be one of the first to create a profile and trade your bags.
              </p>
              <div className="form-row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className={`join-btn ${allFieldsFilled() ? "white" : "grey"}`}
                onClick={() => setStep(2)} // Update the step to 2
                disabled={!allFieldsFilled()}
              >
                Continue
              </button>
            </div>
          )}

          {step === 2 && (
            <div>
              <h1>You are in!</h1>
              <p>Have a minute? Tell us about you.</p>
              <form onSubmit={submitForm}>
                <div className="form-group">
                  <label>What brands are you interested in trading?</label>
                  <select
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select one
                    </option>
                    {brands.map((brand) => (
                      <option key={brand.brandEnum} value={brand.brandEnum}>
                        {brand.brandName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>How often do you trade luxury bags?</label>
                  <select
                    id="frequency"
                    name="frequency"
                    className="form-control"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                  >
                    <option value="" disabled>
                      Select one
                    </option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="every_few_months">Every few months</option>
                    <option value="once_a_year">Once a year</option>
                    <option value="rarely">Rarely</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>What are your primary concerns when trading?</label>
                  <select
                    id="concerns"
                    name="concerns"
                    className="form-control"
                    value={concerns}
                    onChange={(e) => setConcerns(e.target.value)}
                  >
                    <option value="" disabled>
                      Select one
                    </option>
                    <option value="authenticity">
                      Authenticity of the bags
                    </option>
                    <option value="security">Security of transactions</option>
                    <option value="privacy">
                      Privacy of personal information
                    </option>
                    <option value="ease_of_use">Ease of use of the app</option>
                    <option value="quality">Quality of the bags</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className={`join-btn ${
                    questionFieldsFilled() ? "white" : "grey"
                  }`}
                  disabled={!questionFieldsFilled() || loading}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserRegistrationForm;
